import React from 'react'
import Layout from 'src/components/layout/layout'
import PayoutsReport from 'src/components/payouts-report/payouts-report'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'

const IndexPage = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Payouts Report" />
      <PayoutsReport />
    </Layout>
  )
}

export default IndexPage
